<template>
    <el-col :span="24" style="background-color: #ffffff">
        <el-col :span="24">
            <div class="container">
                <div class="handle-box">
                    <el-select
                        v-model="schoolIndex"
                        placeholder="学校"
                        style="float: left; margin-right: 10px; margin-bottom: 10px"
                        :disabled="searchSchoolState"
                        @change="changeSchool"
                    >
                        <el-option v-for="item in schoolIndexList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                    <el-select
                        v-model="collegeIndex"
                        placeholder="二级学院"
                        style="float: left; margin-right: 10px; margin-bottom: 10px"
                        :disabled="searchCollegeState"
                        @change="changeCollege"
                    >
                        <el-option v-for="item in collegeIndexList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                    <el-select
                        v-model="classIndex"
                        placeholder="班级"
                        style="float: left; margin-right: 10px; margin-bottom: 10px"
                        :disabled="searchClassState"
                        @change="changeClass"
                    >
                        <el-option v-for="item in classIndexList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                    <el-date-picker
                        style="float: left; margin-right: 10px; margin-bottom: 10px"
                        v-model="exportTime"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                    <el-input v-model="query.ucn" placeholder="统一ID" class="handle-input mr10"></el-input>
                    <el-input v-model="query.keyword" placeholder="模糊检索" class="handle-input mr10"></el-input>
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
                </div>
                <!-- <div class="handle-box">
                    <el-button
                        style="margin-right: 10px"
                        type="primary"
                        icon="el-icon-plus"
                        @click="addUserBut"
                        class="handle-del mr10 ml10"
                        >添加学生</el-button
                    >
                </div> -->
                <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
                    <el-table-column prop="id" label="外出ID" width="100" align="center"></el-table-column>
                    <el-table-column prop="stuName" label="学生" align="center"></el-table-column>
                    <el-table-column prop="stuUCN" label="统一ID" align="center"></el-table-column>
                    <el-table-column prop="schoolName" label="学校" align="center"></el-table-column>
                    <el-table-column prop="collegeName" label="二级学院" align="center"></el-table-column>
                    <el-table-column prop="className" label="班级" align="center"></el-table-column>
                    <el-table-column prop="applyDay" label="外出日期" align="center"></el-table-column>
                    <el-table-column prop="applyDayLong" label="外出天数" align="center"></el-table-column>
                    <el-table-column prop="applyLocat" label="目的地" align="center"></el-table-column>
                    <el-table-column prop="applyRemark" label="外出事由" align="center"></el-table-column>
                    <el-table-column prop="slapState" label="外出状态" align="center"></el-table-column>
                    <el-table-column prop="leaveTime" label="离校时间" align="center"></el-table-column>
                    <el-table-column prop="returnTime" label="返校时间" align="center"></el-table-column>

                    <el-table-column prop="approL1Name" label="一级审核人姓名" align="center"></el-table-column>
                    <el-table-column prop="approL1Result" label="一级审核结果" align="center"></el-table-column>
                    <el-table-column prop="approL1Remark" label="一级审核附注" align="center"></el-table-column>
                    <el-table-column prop="approL1Time" label="一级审核时间" align="center"></el-table-column>

                    <el-table-column prop="approL2Name" label="二级审核人姓名" align="center"></el-table-column>
                    <el-table-column prop="approL2Result" label="二级审核结果" align="center"></el-table-column>
                    <el-table-column prop="approL2Remark" label="二级审核附注" align="center"></el-table-column>
                    <el-table-column prop="approL2Time" label="二级审核时间" align="center"></el-table-column>

                    <el-table-column prop="approL3Name" label="三级审核人姓名" align="center"></el-table-column>
                    <el-table-column prop="approL3Result" label="三级审核结果" align="center"></el-table-column>
                    <el-table-column prop="approL3Remark" label="三级审核附注" align="center"></el-table-column>
                    <el-table-column prop="approL3Time" label="三级审核时间" align="center"></el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.pageIndex"
                        :page-size="query.pageSize"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                    ></el-pagination>
                </div>
            </div>
        </el-col>
        <!-- 学生添加弹出框 -->
        <el-dialog title="学生添加" :visible.sync="addUserstate" width="30%">
            <el-form ref="form" :model="addUser" label-width="150px">
                <el-form-item label="学校" :required="true">
                    <el-select
                        v-model="schoolIndex"
                        placeholder="请选择学校"
                        @change="changeSchool"
                        style="width: 100%"
                        :disabled="searchSchoolState"
                    >
                        <el-option v-for="item in schoolIndexList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="addUser" label-width="150px">
                <el-form-item label="二级院校" :required="true">
                    <el-select
                        v-model="collegeIndex"
                        placeholder="请选择二级院校"
                        @change="changeCollege"
                        style="width: 100%"
                        :disabled="searchCollegeState"
                    >
                        <el-option v-for="item in collegeIndexList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="addUser" label-width="150px">
                <el-form-item label="班级" :required="true">
                    <el-select
                        v-model="classIndex"
                        placeholder="请选择班级"
                        @change="changeClass"
                        style="width: 100%"
                        :disabled="searchClassState"
                    >
                        <el-option v-for="item in classIndexList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <el-form ref="form" :model="addUser" style="margin-left: 100px">
                <!-- <div style="margin: 18px 0">统一ID</div> -->
                <el-form-item label="统一ID">
                    <el-input type="textarea" :rows="6" v-model="addUser.ucn" placeholder="统一ID（可批量） 一行一个"></el-input>
                </el-form-item>
                <!-- <div style="margin-bottom: 18px"></div> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addUserstate = false">取 消</el-button>
                <el-button type="primary" @click="addUserOk">确定</el-button>
            </span>
        </el-dialog>
        <!-- excell问题数据提示弹出框 -->
        <el-dialog title="Excel批量处理" :visible.sync="errorstate" width="30%">
            <el-table :data="errorList" border style="width: 100%">
                <el-table-column prop="reason" header-align="center" align="center" label="原因"> </el-table-column>
                <el-table-column prop="conent" header-align="center" align="center" label="详情"> </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button @click="errorstate = false">取 消</el-button>
                <el-button type="primary" @click="errorstate = false">确定</el-button>
            </span>
        </el-dialog>
    </el-col>
</template>

<script>
import { fetchData } from '../../api/index';
export default {
    name: 'basetable',
    data() {
        return {
            query: {
                pageIndex: 1,
                pageSize: 10
            },
            tableData: [],
            pageTotal: 0,
            addUserstate: false,
            addUser: {},
            reviseUserstate: false,
            reviseUser: {},
            searchCollegeState: false,
            searchSchoolState: false,
            searchClassState: false,
            userName: '',
            schoolIndex: '',
            collegeIndex: '',
            classIndex: '',
            errorstate: false,
            errorList: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            },
            exportTime: []
        };
    },
    activated() {},
    created() {
        var that = this;
        var schoolList = [];
        var userType = JSON.parse(window.sessionStorage.getItem('userType'));
        for (var i = 0; i < userType.length; i++) {
            var userTypeObj = {
                value: userType[i].schoolId,
                label: userType[i].schoolName
            };
            schoolList.push(userTypeObj);
        }
        this.schoolIndexList = schoolList;
        this.schoolIndex = userType[0].schoolId;
        if (this.schoolIndexList.length <= 1) {
            this.searchSchoolState = true;
        }

        var collegeList = [
            {
                value: 0,
                label: '全部'
            }
        ];
        var userType = JSON.parse(window.sessionStorage.getItem('userType'));
        for (var i = 0; i < userType[0].college.length; i++) {
            var userTypeObj = {
                value: userType[0].college[i].collegeId,
                label: userType[0].college[i].collegeName
            };
            collegeList.push(userTypeObj);
        }

        this.collegeIndexList = collegeList;
        this.collegeIndex = 0;
        if (this.collegeIndexList.length <= 1) {
            this.searchCollegeState = true;
        }

        var classList = [
            {
                value: 0,
                label: '全部'
            }
        ];
        var userType = JSON.parse(window.sessionStorage.getItem('userType'));
        for (var i = 0; i < userType[0].college[0].class.length; i++) {
            var userTypeObj = {
                value: userType[0].college[0].class[i].classId,
                label: userType[0].college[0].class[i].className
            };
            classList.push(userTypeObj);
        }
        this.classIndexList = classList;
        this.classIndex = 0;
        if (this.classIndexList.length <= 1) {
            this.searchClassState = true;
        }
        const start = new Date();
        // var end = start.setTime(start.getTime() + 3600 * 1000 * 24);
        this.exportTime.push(start);
        this.exportTime.push(start);
        this.getgoodList();
    },
    methods: {
        // 获取列表数据
        getgoodList() {
            var that = this;
            var query = {
                data: {
                    page: that.query.pageIndex,
                    limit: that.query.pageSize,
                    schoolId: that.schoolIndex,
                    collegeId: that.collegeIndex,
                    classId: that.classIndex,
                    ucn: that.query.ucn,
                    keyword: that.query.keyword,
                    startTime: that.revisestartTimeBut(this.exportTime[0]),
                    endTime: that.revisestartTimeBut(this.exportTime[1])
                },
                url: '/get_slapOrderList.cls.php'
            };
            fetchData(query).then((res) => {
                if (res.code == 0) {
                    // this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
                that.tableData = res.data;
                that.pageTotal = Number(res.count) || 0;
            });
        },
        // 格式化时间选择器值
        revisestartTimeBut(date) {
            var seperator1 = '/';
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = '0' + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = '0' + strDate;
            }
            return year + seperator1 + month + seperator1 + strDate;
        },
        // 监听学校选择器
        changeSchool() {
            var that = this;
            this.collegeIndexList = [];
            var schoolNum = '';
            var userType = JSON.parse(window.sessionStorage.getItem('userType'));
            for (var i = 0; i < userType.length; i++) {
                if (that.schoolIndex == userType[i].schoolId) {
                    schoolNum = i;
                }
            }
            var collegeList = [];
            var userType = JSON.parse(window.sessionStorage.getItem('userType'));
            if (userType[schoolNum].college) {
                for (var i = 0; i < userType[schoolNum].college.length; i++) {
                    var userTypeObj = {
                        value: userType[schoolNum].college[i].collegeId,
                        label: userType[schoolNum].college[i].collegeName
                    };
                    collegeList.push(userTypeObj);
                }

                this.collegeIndexList = collegeList;
                this.collegeIndex = 0;
                if (this.collegeIndexList.length <= 1) {
                    this.searchCollegeState = true;
                }
            } else {
                this.collegeIndex = '';
            }
            this.getgoodList();
        },
        // 监听二级学院选择器
        changeCollege() {
            // console.log(213);
            var that = this;
            this.classIndexList = [];
            var schoolNum = '';
            var collegeNum = '';
            var userType = JSON.parse(window.sessionStorage.getItem('userType'));
            for (var i = 0; i < userType.length; i++) {
                if (that.schoolIndex == userType[i].schoolId) {
                    schoolNum = i;
                }
            }
            var userType = JSON.parse(window.sessionStorage.getItem('userType'));
            for (var i = 0; i < userType[schoolNum].college.length; i++) {
                if (that.collegeIndex == userType[schoolNum].college[i].collegeId) {
                    collegeNum = i;
                }
            }
            console.log(collegeNum);
            var classList = [];
            var userType = JSON.parse(window.sessionStorage.getItem('userType'));
            if (userType[schoolNum].college[collegeNum].class) {
                for (var i = 0; i < userType[schoolNum].college[collegeNum].class.length; i++) {
                    var userTypeObj = {
                        value: userType[schoolNum].college[collegeNum].class[i].classId,
                        label: userType[schoolNum].college[collegeNum].class[i].className
                    };
                    classList.push(userTypeObj);
                }
                this.classIndexList = classList;
                this.classIndex = 0;
                if (this.classIndexList.length <= 1) {
                    this.searchClassState = true;
                }
            } else {
                this.classIndex = '';
            }
            this.getgoodList();
        },
        // 监听班级选择器
        changeClass() {
            this.getgoodList();
        },
        // 用户搜索
        handleSearch() {
            (this.pageIndex = 1), this.getgoodList();
        },

        // 学生添加
        addUserBut() {
            this.addUserstate = true;
        },
        // 学生上传
        addUserOk() {
            var that = this;
            var query = {
                data: {
                    schoolId: that.schoolIndex,
                    collegeId: that.collegeIndex,
                    classId: that.classIndex,
                    ucn: that.addUser.ucn
                },
                url: '/bind_stuToClass.cls.php'
            };
            fetchData(query).then((res) => {
                if (res.code == 0) {
                    this.addUserstate = false;
                    this.$message.success(res.msg);
                    this.getgoodList();
                    this.errorList = [];
                    for (var i = 0; i < res.data.length; i++) {
                        var collegeObj = {};
                        var errorIndex = Number(that.errorList.length) + 1;
                        collegeObj.reason = '第' + errorIndex + '行';
                        collegeObj.conent = res.data[i].msg;
                        that.errorList.push(collegeObj);
                    }
                    that.addUser.ucn = '';
                    this.errorstate = true;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },

        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val;
            this.$set(this.query, 'pageIndex', val);
            this.getgoodList();
        },
        inputChange() {
            this.$forceUpdate();
        }
    }
};
</script>

<style scoped>
.el-cascader {
    width: 90%;
    padding-right: 10px;
}
.demonstration {
    color: #948c8c;
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
    margin-bottom: 10px;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
.block2 {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
}
</style>
